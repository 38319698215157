import { needsRefresh, refreshAuth } from './auth';

const portal_endpoint = "https://portal-api.hotmic.io";
// const portal_endpoint = "https://portal-test.api.hotmic.io"; //TODO change this back to Prod before deploy
// const portal_endpoint = "https://portal-dev.api.hotmic.io"; //TODO change back to prod before deploy

export const fetchWrapperPortal = async ({ ...args }) => {
  try {
    // Check if we need to refresh the token before making the request
    // Skip refresh check if this is a refresh request itself (to avoid infinite loops)
    if (args.url !== 'auth/refresh/' && needsRefresh()) {
      try {
        await refreshAuth();
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.clear();
        window.location.href = '/auth/login';
        throw refreshError;
      }
    }

    const fetchObj = {};
    fetchObj.headers = args.headers || {};
    fetchObj.method = args.method.toUpperCase();
    fetchObj.body = args.body ? args.body : null;
    fetchObj.headers["Content-Type"] = "application/json";
    fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
    
    // Use provided headers.Authorization if it exists, otherwise use access token
    if (!fetchObj.headers.Authorization && args.addBearer) {
      fetchObj.headers.Authorization = args.token ? args.token : `Bearer ${localStorage.getItem("access_token")}`;
    }

    if (args.signal) {
      fetchObj.signal = args.signal;
    }

    const result = await fetch(`${portal_endpoint}/${args.url}`, fetchObj);
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.error(`HTTP ERROR:`, e);
    throw e;
  }
};
